import $ from 'jquery';

$(() => {
  function initSticky(elem, stickyYOffset, margin) {
    function getCol(elem) {
      const found = elem.parent();
      if (found.parent().is('.row') || found.is('body')) {
        return found;
      }
      return getCol(found);
    }
    const column = getCol(elem);

    let columnBottom = column.offset().top + column.height();

    let yOffset = elem.offset().top;
    let width = elem.parent().width();

    $(window).resize(() => {
      width = elem.parent().width();
      $(window).trigger('scroll');
    });

    $(window).scroll(() => {
      const scrolled = window.pageYOffset + stickyYOffset > yOffset;
      const touchBottom = window.pageYOffset + stickyYOffset + elem.height() + margin > columnBottom;
      const leaveBottom = !touchBottom;
      const top2sticky = !elem.hasClass('sticky') && !elem.hasClass('sticky-bottom') && scrolled;
      const sticky2bottom = elem.hasClass('sticky') && !elem.hasClass('sticky-bottom') && touchBottom;
      const bottom2sticky = !elem.hasClass('sticky') && elem.hasClass('sticky-bottom') && leaveBottom;
      const sticky2top = elem.hasClass('sticky') && !elem.hasClass('sticky-bottom') && !scrolled;
      if (top2sticky) {
        elem.css('width', `${width}px`);
        elem.addClass('sticky');
        elem.removeClass('sticky-bottom');
      } else if (sticky2bottom) {
        elem.addClass('sticky-bottom');
        elem.removeClass('sticky');
      } else if (bottom2sticky) {
        elem.addClass('sticky');
        elem.removeClass('sticky-bottom');
      } else if (sticky2top) {
        elem.removeClass('sticky');
        elem.removeClass('sticky-bottom');
        elem.css('width', '100%');
      } else if (!elem.hasClass('sticky') && !elem.hasClass('sticky-bottom')) {
        yOffset = elem.offset().top;
        columnBottom = column.offset().top + column.height();
      }
    });
    $(window).trigger('scroll');
  }
  const stickyTocTopOffset = 100; /* from assets/scss/components/_toc.scss */
  const margin = 24;              /* from assets/scss/components/_toc.scss */
  $('.sticky-toc').each(function () {
    initSticky($(this), stickyTocTopOffset, margin);
  });
  $('.mainmenu').each(function () {
    initSticky($(this), 0, 0);
  });
});
