import $ from 'jquery';

$(() => {
  $('.rich-text a[href]').each(function () {
    const link = $(this);
    if (link.attr('href').startsWith('mailto')) {
      link.prepend('<i class="fa fa-envelope-o mr-1"></i>');
    } else if (link.attr('href').startsWith('ftp')) {
      link.prepend('<i class="fa fa-download mr-1"></i>');
    } else if (link.attr('href').startsWith('/documents')) {
      link.prepend('<i class="fa fa-file-o mr-1"></i>');
    } else if (link.attr('href').startsWith('http')) {
      link.prepend('<i class="fa fa-external-link mr-1"></i>');
    } else if (link.attr('href').startsWith('//')) {
      link.prepend('<i class="fa fa-external-link mr-1"></i>');
    }
  });
});
