import $ from 'jquery';
import Util from 'bootstrap/js/src/util';

/**
 * ------------------------------------------------------------------------
 * Constantes
 * ------------------------------------------------------------------------
 */

const EVENT_KEY = '.app.navbar';

const HOVER_DELAY = 250;

const Event = {
  RESIZE     : `resize${EVENT_KEY}`,
  CLICK      : `click${EVENT_KEY}`,
  MOUSEENTER : `mouseenter${EVENT_KEY}`,
  MOUSELEAVE : `mouseleave${EVENT_KEY}`
};

const Selector = {
  CONTENT : '.navbar-collapse',
  TOGGLER : '.navbar-toggler',
  SUB_MENU : '.navbar-subnav',
  SUB_MENU_LINK : '.nav-link.has-submenu',
  SUB_MENU_TOP_LINK : '.level-1 > .nav-link.has-submenu'
};

/**
 * ------------------------------------------------------------------------
 * Fonctions
 * ------------------------------------------------------------------------
 */

// Détermine si l'appareil est tactile.
function hasTouch() {
  return 'ontouchstart' in window ||
    typeof window.ontouchstart !== 'undefined';
}

// Initialise la barre de navigation afin de l'adapter en fonction de la
// zone d'affichage. Sur mobile, le composant Collapse est utilisé pour
// déplier/replier les sous-menus.
function initNavBar(navbar) {
  if (!navbar) {
    return;
  }

  const content = navbar.querySelector(Selector.CONTENT);
  const toggler = navbar.querySelector(Selector.TOGGLER);

  const $links = $(navbar).find(Selector.SUB_MENU_LINK);
  const $topLinks = $(navbar).find(Selector.SUB_MENU_TOP_LINK);
  const $subMenus = $topLinks.siblings(Selector.SUB_MENU);

  if (!content || !$links.length) {
    return;
  }

  let isCollapsing = null;

  $(window).on(Event.RESIZE, () => refresh());

  refresh();

  // Méthodes principales

  function refresh() {
    if (_isMenuCollapsing()) {
      if (isCollapsing !== true) {
        _collapse();
      }

      // mets à jour la hauteur maximale du menu principal, positionné de
      // manière absolue, en fonction de la barre de navigation
      const offset = navbar.getBoundingClientRect().bottom;
      const height = _getViewportHeight();

      content.style.maxHeight = `${height - offset}px`;
    } else if (isCollapsing !== false) {
      _uncollapse();

      content.style.maxHeight = 'none';
    }
  }

  // Méthodes internes

  function _getViewportHeight() {
    return document.documentElement.clientHeight;
  }

  function _isMenuCollapsing() {
    // se base sur la visibilité du bouton pour déterminer l'état
    return toggler && toggler.offsetParent !== null;
  }

  function _uncollapse() {
    isCollapsing = false;

    $links.off(EVENT_KEY).removeAttr('data-toggle');

    $topLinks.each(function () {
      const $link = $(this);
      const $menu = $link.siblings(Selector.SUB_MENU);

      if ($menu.length) {
        initMenuToggling($link, $menu, $subMenus);
      }
    });
  }

  function _collapse() {
    isCollapsing = true;

    $subMenus.off(EVENT_KEY);

    $links.off(EVENT_KEY).each(function () {
      const $target = $(Util.getSelectorFromElement(this));
      if (!$target.length) {
        return;
      }

      // initialise le composant Collapse sur les éléments
      if (this.getAttribute('data-toggle') !== 'collapse') {
        this.setAttribute('data-toggle', 'collapse');

        $target.collapse();
      }

      $(this).on(Event.CLICK, (event) => {
        if (event.currentTarget.tagName === 'A') {
          event.preventDefault();
        }

        $target.collapse('toggle');
      });
    });
  }
}

// Initialise la bascule du sous-menu `$target` via le lien `$trigger`.
function initMenuToggling($trigger, $target, $menus) {
  $trigger.on(Event.CLICK, (event) => {
    event.preventDefault();

    if (isShown()) {
      hide();
    } else {
      show();
    }
  });

  if (!hasTouch()) {
    let timeout = null;

    $trigger.on(Event.MOUSEENTER, () => {
      clearTimeout(timeout);
      show();
    });

    $target.on(Event.MOUSELEAVE, () => {
      clearTimeout(timeout);
      timeout = setTimeout(() => hide(), HOVER_DELAY);
    });
  }

  hide();

  // Méthodes principales

  function show() {
    if (!isShown()) {
      $menus.removeClass('show');
      $target.addClass('show');
      $trigger.attr('aria-expanded', true);
    }
  }

  function hide() {
    $target.removeClass('show');
    $trigger.attr('aria-expanded', false);
  }

  function isShown() {
    return $target.hasClass('show') === true;
  }
}

export default initNavBar;
