import $ from 'jquery';

$(() => {
  $.get('/static/img/hdf.svg', (data) => {
    $('.carte-hdf').empty().append(data.rootElement);
    $('.carte-hdf[data-latitude]').each(function () {
      const lat = parseFloat($(this).attr('data-latitude'));
      const lon = parseFloat($(this).attr('data-longitude'));
      const topx = 2.5449;
      const topy = 51.0896;
      const botx = 3.4627;
      const boty = 48.8372;
      const scalex = 6.29;
      const scaley = 23.69;
      const ty = scaley * (topy - lat) / (topy - boty);
      const tx = scalex * (topx - lon) / (topx - botx);
      $(this).find('.mapmarker').each(function () {
        $(this)[0].setAttributeNS(null, 'transform', `translate(${tx}, ${ty})`);
      });
    });
    $('.carte-hdf.carte-hdf-thin').each(function () {
      $(this).find('path:not(.mapmarker)').each(function () {
        $(this)[0].setAttributeNS(null, 'style', 'stroke-width:0.1');
      });
    });
  });
});
