import $ from 'jquery';

$(() => {
  $('.block-titre').find(':header').each(function () {
    const title = $(this).clone();
    title.find('a').remove();
    const html = title.html();
    const id = $(this).find('a').attr('id');
    if (id) {
      $('.toc').append(`<li><a href="#${id}">${html}</a></li>`);
    }
  });
});
