import $ from 'jquery';

$(() => {
  let loaded = false;
  function loadLeaflet(map, done) {
    const path = '/static/location_field';
    if (!loaded) {
      $.getScript(`${path}/leaflet.js`).done(() => {
        $('head').append(
          `<link rel="stylesheet" type="text/css" href=${path}/leaflet.css>`
        );
        loaded = true;
        done();
      });
    } else {
      done();
    }
  }
  function prepareMap(map, mapdiv, height, indexArgName, campagne) {
    mapdiv.html('');
    mapdiv.css('height', height);
    map = L.map(mapdiv[0]).setView([50.004, 2.406], 7);
    const data = mapdiv.attr('data-src');
    let fullLink = '';
    const campagneArg = campagne ? `&campagne=${campagne}` : '';
    if (!mapdiv.hasClass('leaflet-map-full')) {
      fullLink = `<a href="/carto-full?${indexArgName}=${data}${campagneArg}"` +
        'target="_blank">Afficher en grand format</a>, ';
    }
    L.tileLayer('//{s}.tile.osm.org/{z}/{x}/{y}.png', {
      maxZoom: 18,
      attribution: `${fullLink}` +
        'Map &copy; <a target="_parent" href="//openstreetmap.org"' +
        '>OpenStreetMap</a> contributors, <a target="_parent" ' +
        'href="//creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>'
    }).addTo(map);
    return map;
  }
  $('.leaflet-map').each(function () {
    let map;
    const mapdiv = $(this);
    const w = $(window);
    const ratio = Math.max(2 / 3, w.height() / w.width());
    const height = mapdiv.width() * ratio;
    const apiPath = '/api/v2/pages/';
    const campagne = mapdiv.attr('data-campagne');
    loadLeaflet(map, () => {
      map = prepareMap(map, mapdiv, height, 'evts', campagne);

      const icon = {};
      for (const theme of ['primary', 'secondary', 'tertiary']) {
        icon[theme] = L.divIcon({
          className: `leaflet-map-icon text-${theme}`,
          iconSize: [24, 42],
          iconAnchor: [12, 42],
          popupAnchor: [0, -30]
        });
      }

      mapdiv.attr('data-src').split(',').map((id) => {
        $.get(`${apiPath}${id}/`, (data) => {
          data.get_api_queryset.map((evt) => {
            if (!campagne || evt.campagne === campagne) {
              const theme = evt.get_theme_color;
              const coords = [evt.latitude, evt.longitude];
              const content = evt.content;
              L.marker(coords, {
                icon: icon[theme]
              }).addTo(map)
                .bindPopup(content, {
                  className: 'leaflet-map-popup',
                  maxHeight: height - 120,
                  keepInView: true
                });
              return false;
            }
            return false;
          });
        });
        return false;
      });
    });
  });
  $('.leaflet-annonce-map').each(function () {
    let map;
    const mapdiv = $(this);
    const w = $(window);
    const ratio = Math.max(2 / 3, w.height() / w.width());
    const height = mapdiv.width() * ratio;
    const apiPath = '/api/v2/pages/';
    loadLeaflet(map, () => {
      map = prepareMap(map, mapdiv, height, 'annonces', false);

      const icon = {};
      for (const theme of ['primary', 'secondary', 'tertiary']) {
        icon[theme] = L.divIcon({
          className: `leaflet-map-icon text-${theme}`,
          iconSize: [24, 42],
          iconAnchor: [12, 42],
          popupAnchor: [0, -30]
        });
      }

      mapdiv.attr('data-src').split(',').map((id) => {
        $.get(`${apiPath}${id}/`, (data) => {
          data.get_api_queryset.map((evt) => {
            const theme = evt.get_theme_color;
            const coords = [evt.latitude, evt.longitude];
            const content = evt.content;
            L.marker(coords, {
              icon: icon[theme]
            }).addTo(map)
              .bindPopup(content, {
                className: 'leaflet-map-popup',
                maxHeight: height - 120,
                keepInView: true
              });
            return false;
          });
        });
        return false;
      });
    });
  });
});
