import './lib/bootstrap';
import './lib/carte-hdf';
import './lib/sticky';
import './lib/toc';
import './lib/leaflet';
import './lib/links';
import $ from 'jquery';
import initNavBar from './lib/navbar';

// Export jQuery for external usage
window.$ = $;
window.jQuery = $;

// -----------------------------------------------------------------------------
// Main application
// -----------------------------------------------------------------------------

$(window).on('load.app', () => {
  $('.no-js').removeClass('no-js');
  // Initialize Popover and Tooltip on the whole page
  $('[data-toggle="popover"]').popover();
  $('[data-toggle="tooltip"]').tooltip();
  // Initialize big main menu
  initNavBar(document.querySelector('.navbar-main'));
});
